import { Heading, Text } from '@storyofams/react-ui';
import { NextSeo } from 'next-seo';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Container } from '~components';

const getError = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

const messages = defineMessages({
  '401': "It looks like you're not supposed to be here.",
  '404': 'We could not find the page you were looking for.',
  '500': 'Our server had some trouble processing this request.',
  default: 'An unexpected error has occured.',
});

const Error = ({ statusCode }) => {
  const intl = useIntl();
  const content = intl.formatMessage(messages[statusCode]);

  return (
    <>
      <NextSeo title={statusCode} description={content} />
      <Container minHeight="100vh" bgColor={'banana' as any}>
        <Heading
          textAlign="center"
          color={'aloe' as any}
          fontSize={[5, 7]}
          lineHeight="heading"
          as="h1"
        >
          {statusCode}
        </Heading>
        <Text
          textAlign="center"
          color={'black' as any}
          fontSize={[2, 2.5]}
          lineHeight="high"
          maxWidth="480px"
        >
          {content}
        </Text>
        <Button to="/" variant="fig">
          Take me home
        </Button>
      </Container>
    </>
  );
};

Error.getInitialProps = ({ res, err }) => getError({ res, err });

export default Error;
